<template>
  <div class="background-black white p-3" id="company">
    <b-row>
      <b-col lg="12">
        <b-container fluid class="pl-3">
          <b-row class="text-left mt-lg-0 pb-5 ml-lg-5 pl-lg-5">
            <b-col md="4" offset-md="1" offset-lg="0">
              <img
                class="footer-logo-img"
                alt="Vue logo"
                src="../assets/img/tekkis-logo.webp"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="4">
        <b-container
          fluid
          class="d-flex h-100 flex-column pb-5 ml-lg-5 pl-lg-5"
        >
          <!-- <b-row class="text-left mt-lg-0 ml-lg-3">
            <b-col md="12" offset-md="1" offset-lg="0">
              <p class="text-700-18">{{ $t("website.footer.address") }}</p>
              <span><b>HeiTech Village</b></span>
              <br />
              <span>UEP Subang Jaya,</span>
              <br />
              <span>Persiaran Kewajipan, Usj 1, </span>
              <br />
              <span>47600 Subang Jaya, Selangor, Malaysia</span>
              <br />
            </b-col>
          </b-row> -->
          <b-row class="text-left mt-lg-0 ml-lg-3">
            <b-col md="12" offset-md="1" offset-lg="0">
              <p class="text-700-18">{{ $t("website.footer.contact") }}</p>
              <span>hello@tekkis.com.my</span>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4">
        <b-container
          fluid
          class="d-flex h-100 flex-column pb-5 ml-lg-5 pl-lg-5"
        >
          <!-- <b-row class="text-left mt-lg-0 ml-lg-3">
            <b-col md="12" offset-md="1" offset-lg="0">
              <p class="text-700-18">{{ $t("website.footer.contact") }}</p>
              <span>hello@tekkis.com.my</span>
            </b-col>
          </b-row> -->
        </b-container>
      </b-col>
      <b-col lg="4" class="p-0 follow-mobile">
        <b-row class="text-left pb-5 justify-content-md-center">
          <b-col md="5" offset-md="0" offset-lg="0">
            <p class="text-700-18">{{ $t("website.footer.follow") }}</p>
            <a href="https://www.facebook.com/tekkismy/" target="_blank">
              <img
                src="@/assets/img/social/fb.png"
                class="pr-3"
                style="max-width: 40px"
                alt=""
              />
            </a>
            <a href="https://www.instagram.com/tekkismy/" target="_blank">
              <img
                src="@/assets/img/social/ig.png"
                class="pr-3"
                style="max-width: 40px"
                alt=""
              />
            </a>
            <a href="https://my.linkedin.com/company/tekkis-my" target="_blank">
              <img
                src="@/assets/img/social/linkedin.png"
                class="pr-3"
                style="max-width: 40px"
                alt=""
              />
            </a>
            <div class="sharethis-inline-share-buttons"></div>
          </b-col>
          <b-col cols="4"></b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6">
        <b-container fluid class="pl-4 ml-md-2">
          <b-row class="text-left ml-lg-5 pl-lg-5 custom-md-5">
            Copyright Tekkis Sdn. Bhd.
            <span v-html="'&nbsp;' + currentYear + '&nbsp;'"></span> | All
            Rights Reserved
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="6">
        <b-container fluid class="pl-4 ml-md-2">
          <b-row class="text-right ml-lg-5 pl-lg-5 custom-md-5">
            <a href="/termscondition" style="color: #fefefe !important">{{
              $t("website.footer.terms")
            }}</a
            >&nbsp;|&nbsp;
            <a href="/privacypolicy" style="color: #fefefe !important">{{
              $t("website.footer.policy")
            }}</a>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  mounted: {},
};
</script>

<style lang="scss" scoped>
.footer-logo-img {
  max-height: 60px;
}

.follow-mobile {
  @media screen and (max-width: 576px) {
    margin-left: 8%;
  }
}

@media (min-width: 768px) {
  .custom-md-5 {
    margin-left: 2.5rem;
  }
}
</style>
